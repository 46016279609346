.loan-data-right {
    height: 100%;
    .row {
        margin-right: -10px;
        margin-left: -10px;
        .col-md-6,.col-md-12,.col-md-3,.col-md-2,.col-md-4 {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    fieldset {
        margin-bottom: 20px;
    }
    .material {
        input.form-input {
            height: 38px;
            padding: 10px 12px;
            font-size: 10px;
            font-weight: 500;
        }
    }
    .mandatory-fileds {
        margin-bottom: 5px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    .select-box {
        &.single-select {
            .css-yk16xz-control,.css-1pahdxg-control {
                height: 38px;
            }
            .css-g1d714-ValueContainer {
                padding-left: 7px;
                padding-right: 4px;
                border-radius: 5px;
            }
            .css-1hb7zxy-IndicatorsContainer {
                &:after{
                    font-size: 18px;
                }
            }
            .css-1wa3eu0-placeholder {
                font-size: 12px;
            }
        }
    }
}
.form-sub-heading {
    padding-top: 22px;
    padding-bottom: 26px;
    > * {
        font-size: 18px;
        line-height: 30px;
        color: #000;
        font-weight: 500;
    }
}
.label-title {
    font-size: 13px;
    line-height: 20px;
    color: #000;
    font-weight: 500;
}
.form-scroll {
    overflow-y: auto;
    max-height: calc(100% - 120px);
    padding: 0 10px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;
}
.mandatory-fileds {
    display: flex;
    align-items: center;
    width: 100%;
}
.label-box {
    min-width: 135px;
    max-width: 135px;
}
.select-box {
    min-width: 105px;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 12px;
    font-weight: 400;
}
.group-input {
    min-width: 127px;
    margin-left: 7px;
    margin-right: 7px;
    width: 100%;
}
.mr-0 {
    margin-right: 0;
}
.loan-data-tabs {
    .doument-upload-left-panel {
        width: calc(53.4% - 10px);
    }
    .document-list-panel {
        right: calc(-88.6% - 10px);
        width: calc(88.6% - 10px);
    }
}

.errorClass {
    border: red solid 1px;
    border-radius: 10px;
}

.select-box-pay{
    margin:0px;
}

.loandata-radio{
    fieldset{
        padding-bottom: 0;
        margin-bottom: 10px;
    }
    .form-label{
        font-size: 16px !important;
    }
}

.lead-form-filed{
    .react-datepicker-wrapper{
        width: 100% !important;
    }
}

.add-percent-in-input {
    position: relative;
    display: inline-block;
}
  
.add-percent-in-input::after {
    content: '%';
    font-family: "Poppins", sans-serif;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 43%;
    right: 15px;

    transform: translatey(-50%);
}

.percent-loan-data {
    position: absolute;
    top: 7px;
    right: 10px;
}