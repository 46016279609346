.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;

  .modal-main {
    position: fixed;
    background: white;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .close_icn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 21px;
    cursor: pointer;
  }
  .modal-body {
    max-height: 450px;
    overflow-y: auto;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 0px 40px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.view-timeline-popup {
  .modal-body {
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 0px 40px;
  }
  .modal-main {
    border-radius: 8px;
    width: 530px;
    padding: 40px 40px;
    h2 {
      padding: 0px 0 24px 0px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    }
  }
  .timeline-history-tabel {
    max-height: 230px;
    overflow-y: auto;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 0px 40px;
  }
  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 10px;
    tbody {
      tr {
        background: #eff2f9;
        td {
          font-size: 11px;
          line-height: 18px;
          color: rgba($heading-color, 0.6);
          padding: 14px 20px;

          &:nth-child(1) {
            width: 80px;
            padding-left: 0px;
            background: $white;
          }
          &:nth-child(2) {
            width: calc(100% - 80px);
            border-radius: 8px;
          }
          &:nth-child(3) {
            border-radius: 0 8px 8px 0;
          }

          .date {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: $heading-color;
            display: block;
          }
          .time {
            font-size: 10px;
            line-height: 20px;
            color: rgba($heading-color, 0.6);
            display: block;
          }
          .heading {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: $heading-color;
            display: block;
            font-weight: 400;
            word-wrap: break-word;
          }
          .subheading {
            font-size: 11px;
            line-height: 18px;
            color: rgba($heading-color, 0.6);
            display: block;
          }
        }
        &:hover {
          background: #bff8fc;
        }
      }
    }
  }
  &.doc-history-timeline {
    table {
      tbody {
        tr {
          td {
            padding: 14px 15px;
            &:nth-child(3) {
              border-radius: 0 !important;
              width: 90px;
            }
            &:nth-child(4) {
              border-radius: 0 8px 8px 0 !important;
              vertical-align: text-bottom;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .tab-line {
    .tab-list {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      &::after {
        display: none;
      }
    }
  }
}

.view-loandata-popup {
  .modal-main {
    width : fit-content !important
  }
}

.total-loan-amount-warning-popup {
  .modal {
    background: none !important;
    .modal-main {
      border: 1px solid #8c8c8c;
    }
  }
}


.view-login-popup{
  .modal-body {
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-left: -40px;
    padding: 0px 40px;
  }
  .modal-main {
    border-radius: 8px;
    max-width: 400px;
    padding: 40px 40px;
    h2 {
      padding: 0px 0 24px 0px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    }
  }
}

.remark-heading-txt {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: $heading-color;
  padding: 30px 0 13px;
  display: block;
}

.mark-lost-popup {
  .modal-main {
    border-radius: 8px;
    width: 480px;
    padding: 40px 40px;
    h2 {
      padding: 0px 0 20px 0px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    }
  }
  .modal-body {
    padding-top: 7px;
    overflow-y: inherit;
  }
  fieldset {
    margin-bottom: 20px;
  }
}

.image-timeline-popup {
  .modal-body {
    overflow-y: auto;
  }
  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 10px;
    tbody {
      tr {
        background: #eff2f9;
        td {
          font-size: 11px;
          line-height: 18px;
          color: rgba($heading-color, 0.6);
          padding: 14px 20px;
          height: auto;

          &:nth-child(1) {
            width: 63px;
            padding-left: 0px;
            background: $white;
          }
          &:nth-child(2) {
            width: 230px;
            border-radius: 8px 0 0 8px;
          }
          &:nth-child(3) {
            border-radius: 0 8px 8px 0;
            width: 120px;
          }
        }
      }
    }
  }
}

.disbursal-detail-log {
  ul {
    li {
      span {
        word-break: break-all;
      }
    }
  }
}


.history-list-outer {
  display: flex;
  justify-content: space-between;

.user-name {
  max-width: 70px;
  word-break: break-word;
}

}


.p-relative {
position: relative;
}

.view-more-btn {
  position: absolute;
  right: -38px;
  font-size: 37px;
  top: 50%;
  transform: translate(0px, -50%);
  cursor: pointer;
}

.view-more-txt {
  border-top: 1px solid $border-color;
  margin-top: 10px;
}

.rejection-reason-list {
  margin-left: 10px;
  li {
    list-style: unset;
  }
}

.dc-overide-popup{
  .modal-body {
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 0px 40px;
  }
  .modal-main {
    border-radius: 8px;
    width: 530px;
    padding: 40px 20px;
    h2 {
      padding: 0px 0 24px 0px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    }
  }
  table{
    border-spacing: 10px;
    border-collapse: inherit;
    tr{
      background: $white;
      th,td{
        padding: 5px !important;
        &:last-child{
          width: 50%;
        }
      }
    }
    tbody{
      tr{
        border-bottom: 0px;
        td{
          
          border: 1px solid $border-color;
          input{
            width: 100%;
          }
          &:first-child{
            border: 0px;
          }
        }
        &:hover{
          background-color: $white;
        }
      }
    }
  }
}
.dc-override-admin-fee{
  display: flex;
  align-items: center;
  select{
    height: 24px;
    margin-right: 5px;
  }
}