.wrapper-if{
    width: 100%;
    margin: 10px;
    padding: 10px;
}
.sec1-if{
    width: 20%;
    float: left;
}
.sec2-if{
    width: 60%;
    float: left;
}
.sec3-if{
    width: 20%;
    float: left;
}
.request-resubmisison-btn-if{
    margin-top: 12px;
  color: black;
  background: white;
  padding: 5px 20px 5px 20px;
  border: 2px solig gray;
  border-radius: 5px;
}
.mark-as-btn-if{
    margin-top: 12px;
    color: black;
    background: white;
    padding: 5px 20px 5px 20px;
    border: 2px solig gray;
    border-radius: 5px;
}