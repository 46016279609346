.loan-eligibilty-outer {
    background-color: rgba($heading-color, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .loan-eligibilty-popup {
        background: $white;
        border-radius: 20px;
        padding: 30px;
        width: 600px;
        position: relative;

        h2 {
            padding: 0 0 15px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }

        .close_icn {
            position: absolute;
            top: 20px;
            right: 20px;
            background: none;
            border: none;
            font-size: 21px;
            cursor: pointer;
        }

        .loan-user-detail {
            display: flex;
            align-items: center;

            li {

                font-size: 12px;
                padding: 0px 10px;
                position: relative;

                &::before {
                    content: '';
                    background: rgba($heading-color, 0.6);
                    height: 5px;
                    width: 5px;
                    position: absolute;
                    border-radius: 100%;
                    left: -2px;
                    top: 7px;

                }

                &:first-child {
                    padding-left: 0px;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .loan-eligibility-process {
        padding-top: 20px;
        padding-left: 35px;
        max-height: 450px;
        overflow: auto;
        width: calc(100% + 30px);
        padding-right: 30px;

        ul {
            li {
                position: relative;
                padding-bottom: 20px;

                h3 {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    color: $heading-color;
                }

                .sub-heading {
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 17px;
                    display: block;
                    padding-bottom: 15px;
                }

                .img-type {
                    background: #fff;
                    border: 2px solid #BCD6E7;
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    z-index: 2;
                    position: absolute;
                    left: -35px;
                    top: 12px;
                }

                &::after {
                    content: "";
                    border-left: 1px solid #BCD6E7;
                    left: -27px;
                    position: absolute;
                    top: 18px;
                    height: 100%;
                }

                &.completed {
                    color: $heading-color;

                    .img-type {
                        background: #0bbddd;
                        border: 1px solid #0bbddd;
                        position: absolute;
                        top: 2px;
                        left: -35px;

                        &:after {
                            content: "\e927";
                            position: absolute;
                            z-index: 2;
                            left: 1px;
                            top: 1px;
                            font-family: dc !important;
                            color: #fff;
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }

                    &::after {
                        content: "";
                        border-left: 1px solid #0bbddd;

                    }

                    .btn-primary {
                        border-color: #A9A9A9;
                        background: #A9A9A9;
                        box-shadow: none;
                        cursor: auto;
                    }

                    .regerate-btn {
                        background: #0bbddd !important;
                        box-shadow: 0 6px 6px rgba(11, 189, 221, .25) !important;
                        border-radius: 8px;
                        cursor: pointer !important;
                    }

                    .loan-action-report {
                        overflow: auto;

                        &::after {
                            display: none;
                        }
                    }
                }

                &.active {
                    .img-type {
                        border: 2px solid #0bbddd !important;
                    }

                    .loan-action-report {
                        overflow: auto;

                        &::after {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }

        .otp-box {
            .material {
                .form-input {
                    width: 215px;
                    height: 44px;
                }
            }

            button {
                min-width: 105px;
                margin-left: 10px;
            }
        }

        button:disabled,
        button[disabled],
        button[disabled]:hover {
            border-color: #A9A9A9;
            background: #A9A9A9;
            box-shadow: none;
            cursor: auto;
            color: $white;
        }

        .btn-line {
            font-size: 14px !important;
        }
    }

    .pdf-download-txt-icn {
        position: absolute;
        right: 0px;
        top: 0;
        color: $heading-color;

        .ic-Download-icon {
            margin-left: 5px;
        }
    }

    .loan-action-report {
        background: #EFF2F9;
        padding: 20px;
        max-height: 300px;
        margin: 0 auto;
        text-align: center;
        position: relative;
        margin-top: 10px;

        &::after {
            content: '';
            background: rgba($heading-color, 0.1);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
        }
    }

    .btn-reject-approve {
        button {
            min-width: 125px;
            margin-right: 10px;
        }

        &.btn-disabled {
            .btn-line {
                border-color: #A9A9A9;
                color: #A9A9A9;
                cursor: auto;

                &:hover {
                    border-color: #A9A9A9;
                    color: #A9A9A9;
                    background: transparent;
                }
            }

            .btn-primary {
                border-color: #A9A9A9;
                background: #A9A9A9;
                box-shadow: none;
                cursor: auto;
            }
        }
    }

    .ic-file-icon {
        font-size: 50px;
    }
}

.loan-process-ktp {
    h2 {
        text-align: center;
    }

    .btn-reject-approve {
        padding-left: 0px;
        margin: 0 auto;
        text-align: center;
    }
}

.loan-approval-btn {
    min-width: auto !important;
    padding-left: 20px;
    padding-right: 20px;
}

.loan-approval-reupload {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-center {
    margin: 0 auto;
    text-align: center;
}

.loan-approval-upload-report {
    width: 500px !important;

    h2 {
        padding-bottom: 0px !important;
    }
}

.multi-file-upload-btn {
    position: relative;

    input {
        position: absolute;
        left: 87px;
        right: 0;
        height: 44px;
        width: 177px;
        top: 13px;
        opacity: 0;
        cursor: pointer;
    }
}

.multi-browse-othr-btn {
    margin-top: 15px;

    input {
        left: 0;
        top: 0;
        width: 152px;
    }

    span {
        display: block;
        width: 100%;
    }
}

.loan-user-list {
    display: flex;
    align-items: center;

    li {

        font-size: 12px;
        padding: 0px 10px;
        position: relative;

        &::before {
            content: '';
            background: rgba($heading-color, 0.6);
            height: 5px;
            width: 5px;
            position: absolute;
            border-radius: 100%;
            left: -2px;
            top: 7px;

        }

        &:first-child {
            padding-left: 0px;

            &::before {
                display: none;
            }
        }
    }
}

.if-bpkp{
    align-items: center;
    padding-bottom: 20px;
    i{
        color: $heading-color;
        font-size: 22px;
        margin-right: 10px;
    }
    h2{
        padding-bottom: 0px !important;
    }
}
.bpkp-list{
    background: $white;
    box-shadow: 0 1px 4px 0 rgba(40,51,63,0.16);
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    h3{
        font-weight: 600;
        font-size: 18px;
        color: $heading-color;
    }
    ul{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li{
            padding-right: 20px;
            position: relative;
            &::before{
                content: "";
                background: rgba($heading-color, 0.4);
                height: 5px;
                width: 5px;
                border-radius: 100%;
                position: absolute;
                top: 7px;
                left: -12px;
                @media only screen and (max-width: 369px){
                    display: none;
                }

            }
            &:first-child{
                &::before{
                    display: none;
                }
            }
        }
    }
    span{
        color: $accent-color;
    }
}
.bpkp-maindiv{
    padding-right: 15px !important;
    padding-left: 15px !important;
    max-width: 100%;
}
.w-100{
    width: 100%;
}
.bpkp-form{
    span{
        font-weight: 600;
        padding-top: 20px;
        font-size: 18px;
        display: block;
    }
}

.bpkp-outer{
    overflow-y: auto;
    position: inherit !important;
    .loan-eligibilty-popup{
        padding-right: 15px !important;
        padding-left: 15px !important;
        max-width: 100%;
    }
}
.bpkp-check-heading{
    float: left;
    margin-left: 8px;
}

.view-ktp-div-container{
    max-height: 400px;
    overflow: scroll;
}