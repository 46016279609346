.single-select {
    .css-1wa3eu0-placeholder {
        font-size: 13px;
        color: rgba($heading-color, 0.6) !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
    }

    .css-1hb7zxy-IndicatorsContainer {
        svg {
            display: none;
        }
    }

    .css-1okebmr-indicatorSeparator,
    .css-109onse-indicatorSeparator {
        display: none;
    }

    .css-1pahdxg-control,
    .css-yk16xz-control {
        height: 42px;
        border-radius: 8px !important;
        outline: none !important;
        box-shadow: none;
        border-color: #bbbbbb !important;

        &:hover {
            border-color: #bbbbbb !important;
            box-shadow: none;
            border-radius: 8px;
            outline: none !important;
        }
    }

    .css-1hb7zxy-IndicatorsContainer {
        &:after {
            content: "\e917";
            position: absolute;
            right: 3px;
            color: #000;
            font-family: dc !important;
            font-size: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .css-1uccc91-singleValue,
    .css-1v99tuv {
        font-size: 12px;
        font-weight: 500;
        color: $heading-color;
    }

    .css-4ljt47-MenuList,
    .css-11unzgr {
        font-size: 12px;
        color: $heading-color;
    }

    .css-1qprcsu-option,
    .css-1vr111p-option {
        font-weight: normal;

        input {
            position: relative;
            top: 3px;
        }
    }

    .css-1uccc91-singleValue {
        max-width: calc(100% - 20px) !important;
    }
}


.multiselect-dropDown {

    .css-1r4vtzz,
    .css-48ayfv {
        height: 42px;
        border: 1px solid #bbbbbb !important;
        border-radius: 8px !important;
        background: transparent;
        width: 100%;
        box-shadow: none;
        padding-left: 15px;
    }

    .css-1jllj6i-control {
        // width: 100%;

        box-shadow: none;
    }

    .css-6y1x9t-control {
        border-color: hsl(0, 0%, 80%) !important;
        box-shadow: none;

        &:hover {
            border-color: hsl(0, 0%, 80%) !important;
            box-shadow: none;
        }
    }

    .css-1v99tuv,
    .css-11unzgr {
        font-size: 12px;
        color: $heading-color;
    }

    input {
        appearance: auto;
    }

    .css-1qprcsu-option {
        font-weight: normal;
    }

    .css-1gpjby2 {
        svg {
            display: none;
        }

        &:after {
            content: "\e917";
            position: absolute;
            right: 5px;
            color: #000;
            font-family: dc !important;
            font-size: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .css-1v99tuv {
        position: relative;
        top: -1px;
    }

    .css-48ayfv+div {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 1), 0 4px 11px rgba(0, 0, 0, 0.1);
    }
}

.multiselect-dropDown .css-48ayfv+div {
    width: 100%;
}


.multiselect-dropDown .css-1jllj6i-control,
.multiselect-dropDown .css-1qprcsu-option,
.multiselect-dropDown .css-1vr111p-option,
.multiselect-dropDown .css-6y1x9t-control {
    min-width: auto;
}

.multiselect-dropDown .css-1jllj6i-control {
    box-shadow: none;
}

.tat-report-outer {
    .top-filter {
        background: $white;
        border-radius: 8px;
        padding: 20px;
    }
    h1{
        font-weight: 600;
font-size: 22px;
line-height: 30px;
color: $heading-color;
    }

    .lead-list-filter {

        display: flex;
        align-items: center;

        fieldset {
            border: 0px;
            padding: 0px;
            margin-top: 0px;
            width: 130px;
            margin-right: 8px;
            &.dateragefiled{
                width: 175px;
            }
            &.daterange-picker{
                width: 200px;
            }
        }
    }

    .selected-chips {
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0px 0 10px;

            li {
                background: $white;
                padding: 6px 6px 6px 17px;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: $heading-color;
                display: flex;
                align-items: center;
                border-radius: 16px;
                margin-right: 8px;
                border: 1px solid #dde1e9;
                margin-top: 10px;
                span {
                    background: rgba(227, 231, 241, 0.6);
                    height: 20px;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 9px;
                    border-radius: 12px;
                }

                &.clear-txt {
                    background: none;
                    color: $accent-color;
                    border: 0px;
                }
            }
        }
    }


    .more-filter {
        // display: flex;
        // align-items: center;
        // color: $heading-color;
        margin: 0px 0px 0px 10px;

        .more-filter-txt,
        .leass-filter-txt {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            margin-left: 5px;
            white-space: nowrap;
            margin-right: 20px;
        }

        .dropdown {
            display: flex;
            position: relative;

            .dropdown-toggle {
                display: flex;
                align-items: center;
                background: transparent;
                border: 0px;
                padding: 0px;

                &::before {
                    content: "\e907";
                    font-family: dc !important;
                    color: $heading-color;
                }

                color: $heading-color;
            }

            .leass-filter-txt {
                display: none;
            }

            .dropdown-menu {
                box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
                border-radius: 6px;
                width: 554px;
                padding: 30px;
                background: $white;
                // margin-top: 33px;
                z-index: 99;
            }

            &.show {
                .dropdown-menu {
                    display: block;
                    position: absolute !important;
                    // right: 0px !important;
                    top: 18px !important;
                    transform: translate(-44%, 40px) !important;

                    // margin-top: 33px;
                }

                .dropdown-toggle {
                    &::before {
                        content: "\e907";
                        font-family: dc !important;
                    }
                }

                .leass-filter-txt {
                    display: block;
                }

                .more-filter-txt {
                    display: none;
                }
            }
        }

        .more-filter-option {
            h3 {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: $heading-color;
                display: block;
                margin-bottom: 16px;
            }

            .filter-form {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                fieldset {
                    width: 50%;
                    width: 48%;
                    margin-right: 0px;
                }
            }
        }

        .text-btn {
            &.d-flex {
                display: flex;
                align-items: center;
            }
        }

        .btn:not(:disabled):not(.disabled):active:focus,
        .loanbox-revmap-main .btn:not(:disabled):not(.disabled).active:focus {
            box-shadow: none;
        }
    }



    .btn-primary {
        background: $accent-color;
        box-shadow: 0px 6px 6px rgba(11, 189, 221, 0.25);
        border-radius: 8px;
        height: 44px;
        line-height: 41px;
        font-weight: 500;
        font-size: 14px;
        color: $white;
        cursor: pointer;
        min-width: 200px;
        border: 1px solid $accent-color;
    }

    .btn-submit-reset .btn-primary {
        min-width: 150px;
        margin-right: 10px;
    }

    .btn-reset {
        background: transparent;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        border: 0px;
        color: #000;
    }

    .btn:focus,
    .btn.focus {
        box-shadow: none !important
    }

    .more-filter-dropdown {
        margin-top: 0px !important;

        fieldset {
            margin-bottom: 10px;
        }
    }

    .show.dropdown .top-btn-none {
        opacity: 0;
    }

    .more-filter .more-filter-txt,
    .more-filter .leass-filter-txt {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        margin-left: 5px;
        white-space: nowrap;
        margin-right: 20px;
    }


    .tat-report-data-table {
        width: 100%;
        overflow: auto;
        table {
            table-layout: initial
        }
    }

    table {
        table-layout: fixed;
        width: 100%;
        .loan-id-arrow:before {
            content: "\e917";
            font-family: dc!important;
            font-size: 24px;
            position: relative;
            top: 6px;
            margin-left: 0PX;
            left: -2px;
        }

        thead {
            tr {
                background: #E3E7F1;
                border-radius: 6px;

                th {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    color: rgb(0, 0, 0);
                    text-align: left;
                    padding: 15px;
                    vertical-align: text-bottom;
                    min-width: 140px;
                    width: 160px;

                    &::before{
                        content: "\e921";
                        font-family: "dc";
                        transform: rotate(180deg);
                        position: relative;
                        margin-left: -13px;
                        display: inline-table;
                        left: -6px;
                    }

                    &:first-child {
                        border-radius: 6px 0 0 0px;
                        &::before{
                            display: none;
                        }
                    }

                    &:last-child {
                        border-radius: 0 6px 0px 0;
                        width: 110px;
                        white-space: normal;
                        padding-left: 15px;
                        padding-right: 15px;
                        position: -webkit-sticky;
                        position: sticky;
                        top: 0;
                        z-index: 10;
                        right: 0;
                        background: rgba(192, 198, 211, 1);
                        &::before{
                            display: none;
                        }
                    }
                    &:nth-child(2){
                        &::before{
                            display: none;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                background: $white !important;

                td {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 22px;
                    color: rgba(0, 0, 0, 0.6);
                    text-align: left;
                    padding: 13px 15px;

                    &:last-child {
                        width: 110px;
                        white-space: normal;
                        padding-left: 15px;
                        padding-right: 15px;
                        position: -webkit-sticky;
                        position: sticky;
                        z-index: 9;
                        background: #fff;
                        right: 0px;
                        background: rgba(192, 198, 211, 1);
                    }

                    &.tat-report-sub-data {
                        background: #F6F6F6;
                        padding-top: 0px;
                        padding-right: 0px;
                        padding-bottom: 24px;
                    }
                    &.error-td{
                        background: #fff;
                        text-align: center;
                        color: red;
                        font-size: 15px;
                    }
                }

                &.active {
                    background: #F6F6F6 !important;

                    border-bottom: 0px;

                    td {
                        padding-bottom: 24px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .tat-report-sub-table {
        background: $white;
        border-radius: 6px;
        table {
            thead {
                tr {
                    background: #E3E7F1 !important;
                    border-radius: 6px;
                    border-bottom: 0px !important;
                    th {
                        &:last-child{
                            width: 105px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-weight: normal;

                        &:last-child {
                            // width: 140px;
                            white-space: normal;
                            padding-left: 15px;
                            padding-right: 15px;
                            position: -webkit-sticky;
                            position: sticky;
                            z-index: 9;
                            background: #fff;
                            right: 0px;
                            background: rgba(192, 198, 211, 1);
                            width: 105px;
                        }
                    }
                    &:last-child{
                        background: #E3E7F1 !important;
                    }
                }
            }
        }
    }


    .tat-report-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0px;
        .btn-export{
            margin-left: 10px !important;
            padding: 0px 10px;
        }

        h2 {
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 0px;
            color: $heading-color;
        }

        .report-heading-right-panel {
            display: flex;
            align-items: center;

            .report-heading {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: $heading-color;
                margin-right: 0px;
            }

            .report-tab {
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                display: flex;
                padding: 0px 0px;
                margin-left: 10px;

                span {
                    padding: 7px 6px;

                    &.active {
                        background: #0BBDDD;
                        color: $white;
                    }

                    &:first-child {
                        padding-left: 10px;

                        &.active {
                            border-radius: 5px 0 0 5px;
                        }

                    }

                    &:last-child {
                        padding-right: 10px;

                        &.active {
                            border-radius: 0px 5px 5px 0px;
                        }

                    }
                }

            }

        }
    }


    /************************all time required css end**************************/

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-button {
        width: 0;
    }

    ::-webkit-scrollbar-track-piece {
        background: rgba($text-color, 0.1);
        width: 0;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba($text-color, 0.2);
        border-radius: 3px;
    }

    @media only screen and (max-width: 768px) {
        ::-webkit-scrollbar {
            width: 0;
            height: 0px;
        }
    }

    .d-flex {
        display: flex;
    }

    .report-chart-outer{
        padding: 20px;
    }

    .datepicker {
        input {
            width: 100%;
            border: 1px solid #bbbbbb;
            border-radius: 8px;
            box-shadow: 0 0 0;
            width: 100%;
            padding: 11px 15px;
            font-weight: 400;
            font-size: 12px;
            background: url(../../images/date.svg) no-repeat right 7px center;
        }
    
        .react-datepicker__header__dropdown {
            padding: 10px;
    
            select {
                appearance: auto;
                background: transparent;
                border: 0px;
            }
        }
    }

    .css-26l3qy-menu .css-4ljt47-MenuList, .css-26l3qy-menu .css-4ljt47-MenuList>div {
        white-space: normal;
    }

}
button:disabled, button[disabled],button[disabled]:hover {
    background: #919191;
    border-color: #919191;
    box-shadow: none;
    color: #000;
    cursor:not-allowed;
}
.required-msg{
    border: 1px solid red !important;
    border-radius: 8px;
}

.tat-react-select{
    .is-diabled__menu {
        z-index: 10;
    }
}

.multiselect-dropDown.tat-react-select .css-48ayfv + div {
    z-index: 10 !important;
}
.tat-report-outer {
 &.user-tat-report-outer {
    button:disabled,button[disabled],button[disabled]:hover {
        background: #919191 !important;
        border-color: #919191 !important;
        box-shadow: none !important;
        color: #000 !important;
        cursor:not-allowed !important;
    }
 }   
}
.user-tat-report-data-table {
    table {
        tr {
            th {
                &::before {
                    display: none !important;
                }
                &:last-child {
                    background: transparent !important;
                }
            }
            td {
                &:last-child {
                    background: transparent !important;
                } 
            }
        }
    }
    .tat-report-sub-table table tbody tr:last-child {
        background: transparent !important;
    } 
}
