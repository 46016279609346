.if-detail-outer {
    .if-top-detail {
        background: $white;
        border-radius: 0 0 8px 8px;
        padding: 30px;
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        ul {
            display: flex;
            align-items: center;
            padding-top: 12px;

            li {
                color: $heading-color;
                font-size: 12px;
                padding: 0px 12px;
                position: relative;

                &::before {
                    content: '';
                    background: $heading-color;
                    height: 5px;
                    width: 5px;
                    display: inline-block;
                    border-radius: 100%;
                    position: absolute;
                    left: -2px;
                    top: 8px;
                }

                &:first-child {
                    padding-left: 0px;

                    &::before {
                        display: none;
                    }
                }

                &.overdue-txt {
                    color: #E52C2C;
                }

                &.duetoday-txt {
                    color: #F96D08;
                }
            }
        }

        .right-car-price-detail {
            text-align: center;

            label {
                font-size: 16px;
                font-weight: 600;
                line-height: 30px;
                color: $heading-color;
                display: block;
            }

            span {
                font-size: 14px;
                font-weight: 400;
                color: $heading-color;
            }
        }
    }
}

.loan-history-sec {
    background: $white;
    padding: 30px;
    border-radius: 8px;

    table {
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0px 10px;

        tbody {
            tr {
                background: #eff2f9;

                td {
                    font-size: 11px;
                    line-height: 18px;
                    color: rgba($heading-color, 0.6);
                    padding: 14px 20px;

                    &:nth-child(1) {
                        width: 80px;
                        padding-left: 0px;
                        background: $white;
                        color: $heading-color;
                    }

                    &:nth-child(2) {
                        width: 66%;
                        border-radius: 8px 0 0 8px;
                    }

                    &:nth-child(3) {
                        border-radius: 0 8px 8px 0;
                    }

                    .heading {
                        word-break: break-word;
                        color: $heading-color;
                        display: block;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                    }

                    .link {
                        color: $heading-color;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.loan-history-calcultor-outer {
    margin-top: 30px;

    .loan-history-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        span {
            font-size: 20px;
            font-weight: 600;
            color: $heading-color;
        }

        .btn-line {
            min-width: auto;
            padding: 0px 20px;
        }
    }

    .status-bottom-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
    }

    .history-table-outer {
        max-height: 400px;
        overflow-y: auto;
        width: calc(100% + 15px);
        padding-right: 10px;
    }
}

.interest-calcultor {
    background: $white;
    padding: 15px 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .interest-date-picker {
        h3 {
            font-size: 20px;
            font-weight: 600;
            color: #000;
            padding-bottom: 20px;
        }

        .btn-primary {
            min-width: auto;
            width: 100%;
            margin-top: 20px;
        }

    }

    .interest-detail {
        background: #EFF2F9;
        border-radius: 8px;
        padding: 0px 15px;
        width: 250px;
        text-align: center;
        margin-left: 30px;

        ul {
            li {
                padding-top: 15px;
                color: $heading-color;

                label {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    display: block;
                }

                span {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 30px;
                }
            }
        }
    }
}

.t-right {
    text-align: right;
}


.overdue-txt {
    color: #E52C2C;
}

.duetoday-txt {
    color: #F96D08;
}

.inverntory-funding-bg {
    background: $white;
    border-radius: 8px;
    padding:0px 20px 20px;
    margin-bottom: 20px;

    h3 {
        font-size: 18px;
        color: $heading-color;
        font-weight: 500;
    }

    span {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: $heading-color;
    }
    .col-md-3{
        margin-top: 20px;
    }
}

.inverntory-funding-loan-table {
    &.lead-list-data-table {
        tbody {
            tr {
                td {
                    height: auto;
                }
            }
        }
    }
}
.loan-history-update-popup{
    .modal-main{
        width: 420px;
    }   
}

.dealer-history-popup{
    .modal-main{
        width: 680px;
    }   
}
.change-status-popup-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
    .btn-primary{
        min-width: inherit;
        width: 48%;
    }
    .btn-line{
        min-width: inherit;
        width: 48%;
    }
}
.submit-upload-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
 
    .btn-primary{
        min-width: inherit;
        width: 48%;
    }
 .img-bx-select-opt{
    width: 48%;
    .btn-primary{
        min-width: inherit;
        width: 100%;
    }
    .upload-id-proof-btn{
        padding-bottom: 0px;
    }
 }
}
.btn-line.edit-loan-btn{
    width: 100%;
}

.tab-calculator-dif{
    background-color: $white;
    padding: 20px;
    h2{
        padding-bottom: 15px;
    }
    .interest-calcultor{
        align-items: flex-start !important;
    }
}