.white-bg {
    background: $white;
}
.top-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-line {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        margin-left: 10px;
        i {
            font-size: 20px;
            margin-right: 5px;
        }
    }
}
.tab-line {
    padding-top: 15px;
    padding-bottom: 10px;
    .tab-list {
        display: flex;
        align-items: center;
        .nav-item {
            margin-right: 20px;
            cursor: pointer;
            .nav-link {
                border-bottom: 2px solid transparent;
                padding-bottom: 8px;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: rgba($heading-color, 0.87);
                width: max-content;
                &.active {
                    font-weight: 600;
                    color: rgba($accent-color, 0.87);
                    border-bottom: 2px solid $accent-color;
                }
            }
        }
    }
}
.display-block {
    display: block;
}
.display-none {
    display: none;
}
.overflow-hidden {
    overflow: hidden;
}
[hidden] {
    display: none;
}
.payment-config-outer{
    .accordion__button:before {
        position: absolute;
        content: '+';
        right: 20px;
        font-size: 30px;
    }
    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        content: '-';
    }
    .accordion__button {
        cursor: pointer;
        width: 100%;
        text-align: left;
        border: none;
        position: relative;
    }
    .accordion__panel {
        animation: fadein 0.35s ease-in;
        padding: 20px;
    }
}
.add-incentive-accordion {
    .accordion__item {
        border-radius: 8px;
        margin-bottom: 20px;
    }
    .accordion__button {
        background: #E3E7F1;
        border-radius: 8px 8px 0 0;
        padding: 10px 60px 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2 {
            color: $heading-color;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
        }
    }
    .sub-accordion {
        .accordion__button {
            background: transparent;
            border-bottom: 1px solid $border-color;
        }
        .accordion__item {
            border: 1px solid $border-color;
            border-radius: 8px;
        }
    }
    .right-action-item {
        display: flex;
        align-items: center;
        li {
            margin-left: 40px;
            i {
                font-size: 20px;
            }
            .ic-delete-icn {
                font-size: 28px;
            }
        }
    }
}
.ToggleSwitch {
    position: relative;
    // width: 5rem;
    // height: 2.35rem;
    // display: inline-block;
    &.ToggleSwitch__rounded {
        .Slider {
            border-radius: 15rem;
            background: #eceff1;
            border: 1px solid #a0a0a0;
            &:before {
                border-radius: 50%;
            }
        }
    }
    .ToggleSwitch__wrapper {
        position: relative;
        width: 46px;
        height: 26px;
        .Slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.4s ease;
            &:before {
                width: 21.33px;
                height: 21.33px;
                position: absolute;
                background: #fff;
                content: "";
                margin: 0;
                padding: 0;
                top: 50%;
                left: 0.1rem;
                transform: translateY(-51%);
                transition: 0.4s;
                cursor: pointer;
                // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            }
            &.isChecked {
                background: rgba($accent-color, 0.2);
                border-color: rgba($accent-color, 0.2);
                &:before {
                    left: calc(100% - 0.1rem - 1.4rem);
                    background: $accent-color;
                }
            }
        }
    }
}
.basic-detail-filter {
    display: flex;
    align-items: center;
    fieldset {
        width: 225px;
        margin-right: 8px;
    }
}
.single-select {
    .react-select__value-container--has-value .react-select__placeholder {
        top: -4px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
    }
    .react-select__control--menu-is-open .react-select__placeholder {
        top: -4px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
    }
    .react-select__value-container {
        overflow: inherit !important;
        background: $white;
        padding-left: 13px;
        padding-right: 13px;
    }
    .css-26l3qy-menu {
        margin-top: 0px;
    }
    .material {
        border: 1px solid $border-color;
        border-radius: 8px;
    }
    .single-select .react-select__control,
    .single-select .react-select__control {
        border-color: transparent !important;
    }
}
.left-right-panel-outer {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    height: 100%;
    // margin-top: 30px;
   
}
.area-drop-downfiled {
    width: 160px;
    margin-right: 10px;
}
.filed-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: $heading-color;
}
.payment-config-outer{
    .form-filed {
        width: 160px;
        margin-right: 10px;
        fieldset {
            margin-bottom: 10px;
        }
    }
}
.area-detail-filter {
    display: flex;
    justify-content: space-between;
}
.add-incenative-left-panel {
    width: 80%;
    padding-top: 30px;
    padding-top: 30px;
    background: $white; 
    .area-detail-filter-outer {
        // display: flex;
    }
}
.br-bottom {
    border-bottom: 1px solid $border-color;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.app-submit-outer {
    margin-bottom: 30px;
    table {
        width: 60%;
    }
}
.p-l-r-15 {
    padding-left: 15px;
    padding-right: 15px;
}
.minus-l-r-15 {
    margin-left: -15px;
    margin-right: -15px;
}
.payment-config-outer {
    .tab-line {
        padding-bottom: 0px !important;
    }
    .nav-pills {
        .nav-item {
            a {
                background: transparent;
                &.active {
                    background: transparent;
                    border-radius: 0px;
                }
            }
        }
    }
    .single-select .css-g1d714-ValueContainer,
    .single-select .react-select__value-container,
    .single-select .react-select__value-container--has-value {
        background: rgba(242, 242, 242, 0);
    }
    .bm-selected-filter {
        background: $white;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bm-filter {
            display: flex;
            align-items: center;
            span {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                color: rgba($heading-color, 1);
                margin-right: 15px;
            }
            .single-select {
                width: 200px;
            }
            
            fieldset {
                width: 300px;
                margin-right: 10px;
        
                label {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    display: block;
                    margin-bottom: 5px;
                }
        
                .form-input {
                    border: 1px solid #bbbbbb;
                    border-radius: 8px;
                    box-shadow: 0 0 0;
                    width: 100%;
                    padding: 10px 15px;
                    font-weight: 500;
                }
            }
        }
    }
    .area-detail-filter-outer {
        table {
            thead {
                tr {
                    background: transparent;
                    border-radius: 0px;
                    th {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 30px;
                        color: rgba($heading-color, 1);
                        padding-bottom: 0px;
                    }
                }
            }
            tr{
                th,td{
                    &:nth-child(1){
                        width: 23%;
                    }
                    &:nth-child(2){
                        width: 23%;
                    }
                    &:nth-child(3){
                        width: 23%;
                    }
                    
                }
            }
            tbody {
                tr {
                    border: 0px;
                    &:hover {
                        background: transparent;
                    }
                    td {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 30px;
                        color: rgba($heading-color, 1);
                    }
                }
            }
        }
        .form-filed {
            fieldset {
                margin-bottom: 0px;
            }
        }
        .material {
            input {
                &.form-input {
                    height: 42px;
                }
            }
        }
        .single-select {
            width: 160px;
        }
    }
    .action-icn {
        display: flex;
        li {
            border: 1px solid;
            border-radius: 4px;
            height: 40px;
            width: 40px;
            min-width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            i {
                font-size: 20px;
            }
            &.edit-icon {
                border-color: #0BBDDD;
                color: #0BBDDD;
            }
            &.delete-icon {
                border-color: #FF8080;
                color: #FF8080;
            }
        }
        .btn-primary{
            min-width: auto;
            display: inline-block;
            padding: 0px 10px;
            white-space: nowrap;
        }
    }
    .on-off-switch{
        margin-right: 50px;
    }
    .row-delete-icn{
        position: absolute;
        right: 58px;
        font-size: 23px;
    } 
    .accordion__panel{
        padding-top: 0px;
        padding-left: 0px;
    }
    .list-table-outer{
        max-height: 440px;
        overflow-y: auto;
    }
}
.toggle-status-txt{
    margin-right: 0px !important;
    .Slider {
    &.false::after{
        content: "Inactive";
        left: -70px;
        position: absolute;
    }
    &.isChecked::after{
        content: "Active";
        left: -70px;
        position: absolute;
    }
}
}
.formula-txt{
    color: rgba($heading-color, 1);
}
.formula-function-txt{
    color: $formula-color1;
}
.formula-multiplier-txt{
    color: $formula-color4;
}
.formula-function-multiplier{
    color: $formula-color2;
}
.formula-constant-txt{
    color: $formula-color3;
}
.add-formula-popup{
    .modal-main{
        width: 680px;
    }
    ul{
        padding-bottom: 10px;
        li{
            display: flex;
            align-items: center;
            label{
                width: 150px;
                font-weight: 500;
                font-size: 12px;
                line-height: 30px;
                color: rgba($heading-color, 1);
            }
            span{
                font-weight: 500;
                font-size: 12px;
            }
        }
    }
}
.formula-filed {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    fieldset{
        width: 140px;
        margin-right: 10px;
    }
    .material{ 
    input{
    &.form-input{
        height: 42px;
    }}}
}
.back-icn {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #000;
    padding-bottom: 15px;
    display: block;
    cursor: pointer;
    .ic-arrow_back {
        margin-right: 15px;
    }
}
.add-interval-panel {
    background: #E3E7F1;
    border-radius: 8px;
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .add-interval-btn{
         display: flex;
         align-items: center;
    }
    .single-select{
        width: 150px;
        margin-right: 10px;
    }
}

.view-formula-button {
    width: 160px;
    height: 40px;
    background: #FFEBB4;
    border-radius: 8px;
    cursor: pointer;
}

.delete-button {
    background-color:#FF8080;
    color:#FFF;
    width:175px;    
}

.delete-button:hover{
    background-color:#FF8080;
    color:#FFF;
    width:175px;
}

.internal-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}