.trivo-margin-booster{
    ul{
        li{
        
            label{
                margin-left: 0px;
                width: 140px;
            }
        }
    }
    .branch-list-ul{
        li{
            padding-bottom: 15px;
            label{
                width: 250px    ;
            }
        }
    }
}
.modal-header{
    h2{
        padding-bottom: 12px;
    }
}

    .btn-line-w-auto{
        .btn-line{
            min-width: auto;
            padding: 0px 25px;
        }
        &.add-new-agent-fee-btn{
            right: 180px;
        }
    }


    .trivo-agent-fee-outer{
        ul{
            li{
            
                label{
                    margin-left: 0px;
                }
            }
        }
        .branch-list-ul{
            li{
                align-items: center;
                padding-bottom: 15px;
                label{
                    width: 250px    ;
                }
            }
        }
   
    }

  .trivo-tax-booster{
    ul{
        li{
        
            label{
                margin-left: 0px;
            }
            .percent-parent{
                top: 0px;
                 .percent {
                position: absolute;
                top: 12px;
            }
        }
        }
    }
    .branch-list-ul{
        li{
            padding-bottom: 15px;
            label{
                width: 250px;
                margin-top: 8px;
            }
        }
    }
  }  