.margin-booster-tabs-outer, .agent-fee-tabs-row {
    margin-top: 20px;
    position: relative;

    .nav-pills {
        .nav-item {
            a {
                background: $white;

                &.active {
                    background: #0bbddd;
                    .new-tab {
                        color: white;
                    }
                }
            }
        }
    }

    .add-new-btn {
        position: absolute;
        right: 0px;
        top: -2px;
    }

    .add-new-agent-fee-btn {
        position: absolute;
        right: 130px;
        top: -2px;
    }

    .bm-scheme-history-btn {
        position: absolute;
        right: 0px;
        top: -2px;
    }

    .ic-clearclose {
        font-size: 20px;
        padding-left: 10px;
    }

    .new-tab {
        color: #0bbddd;
        font-size: 12px;
        font-weight: bold;
        padding-bottom: 3px;
        margin-right: 5px;
    }
}

.agent-fee-tabs-row {
    .tab-list {
        width: 80%;
        overflow-x: scroll;
        white-space: nowrap;
    }
    ::-webkit-scrollbar {
        height: 0px;
        width: 0px;
    }
}

.scheme-list-table {
    padding-top: 20px;

    tr {

        th,
        td {
            &:nth-child(1) {
                width: 20%;
            }

            &:nth-child(2) {
                width: 20%;
            }

            &:nth-child(3) {
                width: 45%;
            }

            &:nth-child(4) {
                width: 15%;
            }
        }
    }

    .user-name-list {
        white-space: nowrap;
        display: flex;

        .user-list {
            width: calc(100% - 80px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }

        .more-opt {
            align-self: center;
            width: 80px;
            cursor: pointer;
        }
    }
}

.popup-user-list {
    padding: 10px;
    &-item{
        line-height: 30px;
    }
}


.booster-filter-top, .agent-fee-filter-top{
    background: $white;
    border-radius: 8px;
    margin: 20px 0;
    padding: 20px;
    display: flex;

    fieldset {
        width: 300px;
        margin-right: 10px;

        label {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            display: block;
            margin-bottom: 5px;
        }

        .form-input {
            border: 1px solid #bbbbbb;
            border-radius: 8px;
            box-shadow: 0 0 0;
            width: 100%;
            padding: 10px 15px;
            font-weight: 500;
        }
    }

    .btn-submit-reset {
        margin-top: 26px;
        margin-left: 20px;
    }

    .required-users > div{
        border: 1px solid red !important;
        border-radius: 8px !important;
       
    }
}