.radio-type-txt {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    .form-label {
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        margin-right: 30px;
        color: $heading-color;
    }

    .custom-checkbox {
        margin-right: 35px;
    }
}

.form-tab-right-panel {
    h2 {
        padding-bottom: 23px;
    }

    fieldset {
        margin-bottom: 20px;

        &.text-area-filed {
            margin-bottom: 14px;
        }
    }

    .row {
        margin-right: -10px;
        margin-left: -10px;

        .col-md-6,
        .col-md-12 {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

.btn-save-remarks {
    .btn-primary {
        min-width: 177px;
        margin-right: 10px;
        margin-bottom: 10px;

        &.cancel-btn {
            min-width: 240px;
        }
    }

    .btn-line {
        min-width: 125px;
    }

    .btn-disable {
        background: #919191;
        border-color: #919191;
        box-shadow: none;
    }
}

.warning-popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 0 4rem;
}

.ho-refund-calculator-popup {
    margin-top: 5px;
    fieldset { 
        margin-bottom: 30px !important;
    }
    .ho-refund-amount {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        span {
            font-size: 18px;
            font-weight: bold;
        }
        fieldset {
            margin-bottom: 0px !important;
        }
    }
}

.ho-form-popup-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.btn-calculate-manual {
    
    display: flex;
    position: relative;
    top: 3px;
    align-items: center;
    margin-bottom: 30px;
    
    .btn-line {
        min-width: 100px;
        margin-left: 6px;
        color:#6ca8b3;
        border: none;
        box-shadow: 0px 5px 6px -1px #5c767a; 
    }

    .btn-disable {
        background: #919191;
        color: black;
        border-color: #919191;
        box-shadow: none;
    }

    .tla-calculate-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 1rem;
        color: black;
        font-size: 12px;
        .missing-tla-data {
            cursor: pointer;
            color: rgba(11, 189, 221, 0.87);
            &:hover {
                text-decoration: underline;
            }
        }
        .click-disabled {
            cursor: not-allowed !important;
            opacity: 0.6;
            pointer-events: none;
            &:hover {
            }
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 11 !important;
  
    .modal-main {
      position: fixed;
      background: white;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .close_icn {
      position: absolute;
      top: 20px;
      right: 20px;
      background: none;
      border: none;
      font-size: 21px;
      cursor: pointer;
    }
    .modal-body {
      max-height: 450px;
      overflow-y: auto;
      width: calc(100% + 80px);
      margin-left: -40px;
      padding: 0px 40px;
    }
  }

.single-select {
    .react-select__value-container--has-value .css-1wa3eu0-placeholder, .react-select__value-container--has-value .react-select__placeholder {
        top: -8px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
    }

    .react-select__control--menu-is-open .css-1wa3eu0-placeholder, .react-select__control--menu-is-open .react-select__placeholder {
        top: -8px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
    }

    .css-g1d714-ValueContainer, .react-select__value-container, .react-select__value-container--has-value {
        overflow: inherit !important;
        background: $white;
        padding-left: 13px;
        padding-right: 13px;
    }

    .css-26l3qy-menu {
        margin-top: 0px;
    }

    .is-diabled--is-disabled {
        .is-diabled__value-container, .is-diabled__control--is-disabled {
            background-color: #f8f8f8;
            min-height: 42px;
            border-radius: 8px;
        }
    }
}

.label-txt-select {
    width: 100% !important;
}

.lead-form-filed {
    padding-top: 5px !important;

    h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        padding: 25px 0px 20px;
        color: $heading-color;
    }
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.doument-gallery-tabs {
    .nav-pills {
        width: 100%;
        margin-left: 0px;
        box-shadow: none;
        padding: 0px;
        padding-right: 0px;
        justify-content: flex-start;

        .nav-item {
            a {
                background: transparent;
                padding: 0px;
                height: auto;
                border-bottom: 2px solid transparent;
                border-radius: 0px;
                color: rgba($heading-color, 0.5);
                margin-right: 35px;
                padding-bottom: 3px;

                &.active {
                    border-color: #0bbddd;
                    color: #0bbddd;
                    font-weight: 500;
                }
            }

            &:last-child {
                a {
                    margin-right: 0px;
                }
            }
        }
    }

    .tab-content {
        width: 100%;
        margin-left: 0px;
        padding-right: 0px;
    }

    .tab-list {
        position: relative;
        width: 100%;
        margin-bottom: 20px;

        &:after {
            content: "";
            background: rgba($heading-color, 0.1);
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: 0px;
        }
    }
}

.doc-section-headers {
    overflow-x: scroll;
    a {
        margin-right: unset !important;
        width: 150px;
    }
}

.doc-section-headers::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}

.file-submit-selet-list {
    display: flex;
    width: 100%;
    align-items: center;

    fieldset {
        width: 100%;
    }

    .grid-icn {
        .ic-gridview {
            font-size: 20px;
            margin-left: 15px;
        }
    }
}

.doc-count {
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #676767;
    display: block;
    padding: 10px 0;

    span {
        font-weight: 500;
        color: $heading-color;
        padding-left: 5px;
    }
}

.verfication-img {
    padding: 50px 0;

    img {
        width: 100%;
        max-height: 350px;
    }
}

.image-opt {
    position: absolute;
    top: 41%;
    width: 100%;
    left: 0;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    padding: 0px 15px;
    width: 100%;
    &.no-doc-btn{
        bottom: 60px;
        top: inherit;
    }
    
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        li {
            padding: 0px 5px;

            a {
                background: #ffffff;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
                border-radius: 10px;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                font-size: 20px;
            }

            .ic-history {
                font-size: 17px;
            }
        }
    }

    .btn-submission-approved {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .btn-line {
            min-width: auto;
            padding: 0px 10px;
            height: 34px;
            line-height: 31px;
            font-size: 12px;
            font-weight: normal;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    &.doc-image-opt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        ul {
            margin-bottom: 0px;
        }

        .btn-submission-approved {
            justify-content: flex-end;
        }
    }
    &.doc-image-opt{
        top: inherit;
    left: 0;
    right: 0px;
    margin: 0 auto;
    text-align: center;
    bottom: 60px;
    .btn-submission-approved{
        justify-content: center;
    }
    }
}

.lead-form-heading {
    position: relative;
    .main-heading {
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
    }
}

.gird-view-select-opt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.image-bx {
    background: #f4f6fb;
    border-radius: 6px;
    padding: 10px;
    height: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    width: 100%;
    overflow: hidden;
    .ic-pdf-file {
        font-size: 60px;
    }  
}

.grid-item-list {
    .row {
        margin-right: -10px;
        margin-left: -10px;
    }

    .col-md-4 {
        padding-right: 10px;
        padding-left: 10px;
        overflow: hidden;
    }

    .img-bx-select-opt {
        margin-top: 20px;
    }
}

.check-box-small {
    .custom-control-label {
        padding-left: 26px;
        font-weight: normal;
        font-size: 11px;
        line-height: 14px;
        color: rgba($heading-color, 0.5);

        &:before {
            height: 14px;
            width: 14px;
            border: 1px solid rgba($heading-color, 0.5);
        }

        &:after {
            top: -1px;
            width: 16px;
            height: 16px;
            left: -1px;
        }
    }

    .custom-control-input {
        width: 18px;
        height: 18px;
    }

    &.select-check-bx {
        .custom-control-label {
            padding-left: 26px;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: rgba($heading-color, 1);
        }
    }
}

.btn-line-img {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    position: absolute;
    bottom: 10px;
    right: 15px;

    .btn-line {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        height: 34px;
        line-height: 34px;
        padding: 0px 10px;
        margin-left: 8px;
        border-radius: 6px;
        min-width: auto;
    }
}

.list-icn {
    position: relative;
    top: -4px;
    font-size: 17px;
}

.info-icn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
}

.p-r-30 {
    padding-right: 30px !important;
}

.po-upload-img {
    margin-top: 20px;

    &.Add-Financer-popup {
        .img-bx-select-opt {
            .image-bx {
                height: auto;
                flex-direction: row;
            }
        }

        .more-file-upload {
            img {
                height: 22px;
                margin-right: 10px;
            }

            input[type="file"] {
                height: 100%;
            }
        }
    }
}

.upload-preview {
    display: block;
    width: 100%;
    margin-top: 20px;

    .img-preview-bg {
        background: #EFF2F9;
        border-radius: 8px;
        height: 260px;
        position: relative;
        padding: 10px;
        overflow: hidden;
        text-align: center;
        margin-top: 0 auto;

        img {
            height: 100%;
        }

        i {
            position: absolute;
            right: 10px;
            top: 10px;
            background: rgba(31, 31, 31, 0.6);
            height: 16px;
            width: 16px;
            color: $white;
            border-radius: 100%;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .reupload-txt {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $heading-color;
        text-decoration: underline;
    }
}
.skip-txt {
    position: absolute;
    right: 0px;
    top: 5px;
    text-decoration: underline;
    cursor: pointer;
}

.error-msg {
    font-size:12px;
    color: red;
}


.image-varification-slider {
    .pfd-img-height {
      >div {
        >div {
          display: flex;
          top: inherit !important;
          bottom: 20px;
          background: transparent !important;
          box-shadow: none !important;
          border: 0px !important;
          right: 0px !important;
          width: 100%;
          align-items: center;
          justify-content: center;
  
          >div {
            border-radius: 100%;
            background: $white;
            width: 30px !important;
            height: 30px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px 5px;
            border-bottom: 1px solid rgb(204, 204, 204);
  
            svg {
              height: 18px !important;
              width: 18px !important;
              padding: 0px !important;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 auto;
  
              path,
              polygon {
                stroke: #000;
              }
            }
          }
        }
      }
  
      .pan-container {
        >div {
          height: auto !important;
          width: auto !important;
          margin: 0px !important;
        }
      }
    }
  
    .image-opt {
    //   left: 0px;
      padding: 0px 20px 0px 25px;
      .icn-pre-slide{
        position: absolute;
        left: 0px;
        top: 50%;
      }
      .icn-next-slide{
        right: 0px;
        position: absolute;
        top: 50%;
      }
    }
    
  }

  .pan-container {
    height: 350px!important;
    overflow: hidden;
}

.carousel__slide {
    display: none;
    &.carousel__slide--active {
        display: block;
    }
}
.pdf-icons-normal-gallery {
    font-size: 283px;
    float: left;
    width: 100%;
    text-align: center;
}

.info-icn-lead-details {
    position: absolute;
    top: 17px;
    right: 10px;
    cursor: pointer;
}

.calculator-icon-field {
    .calculator-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 17px;
        width: auto;
        cursor: pointer;
    }
    .form-input {
        padding-right: 40px !important;
    }
}

.calculator-image-preview {
    margin-top: unset;
    margin-bottom: 20px;
}

.tat-export-button {
    display: flex;
    justify-content: space-between;
}

.nim-calculator-img {
    justify-content: center;
    display: flex;
    max-height: 250px;
    margin-bottom: 20px;
    overflow: hidden;
}