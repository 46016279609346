.extra-incentive-filed-outer{
    .accordion__item{
        background: $white;
        margin-top: 20px;
        border-radius: 8px;
        .accordion__heading{
            border-bottom: 1px solid $border-color;
            position: relative;
            margin: 0;
            line-height: normal;
        }
        .accordion__button {
            background: transparent;
            border: 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            padding: 15px 30px;
            text-align: left;
            cursor: pointer;
            color: rgba($heading-color, 1);
            &:after {
                content: "\e907";
                transition: transform .2s ease-in-out;
                font-family: dc;
                font-size: 20px;
                position: absolute;
                right: 20px;
            }

            &[aria-expanded="true"]:after {
                content: "\e90c";
            }
        }
        .accordion__panel{
            padding: 20px;
        }
    }
    .toggle-switch-outer{
        margin-top: 25px;
    }
    .ToggleSwitch{
        .toggle-status{
            color: #E02020;
        }
    }
}

.extra-incentive-filter-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-radius: 8px;
    margin-top: 20px;
    padding: 20px;
    fieldset{
        margin: 0px;
    }
.add-range-btn{
    padding-top: 0px;
}
}
.extra-incentive-table-outer{
margin-top: 20px;
.action-btn {
    display: flex;
    li {
        height: 32px;
        width: 32px;
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
        
        &:last-child {
            margin-right: 0px;
        }
    }
}
table{
    tr{
        th{
            &:nth-child(2){
                width: 15%;
            }
        }
    }
    tbody{
       
         td{
            &:nth-child(2) {
                padding: 0;
            }
         }
    }
    .sub-table{
        tr{
            td{
                &:first-child{
                    padding-left: 9px;
                    width: 17.5%;
                }
            }
        }
    }
}
}
.v-top{
    vertical-align: top;
}
.extra-incentive-tab-outer{
        position: relative;
        }
.extra-incentive-btn{
    position: absolute;
    right: 0px;
    top: -36px;
    padding-top: 0px;
}
.extra-incentive-popup{
    .modal-header{
        h2{
            padding-bottom: 10px;
        }
    }
    .d-flex{
        justify-content: space-between;
        padding-bottom: 15px;
        padding-top: 20px;
        h2{
            padding-bottom: 0px;
        }
    }
    .btn-line{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .back-icn {
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #000;
        padding-bottom: 25px;
        display: block;
        cursor: pointer;
        .ic-arrow_back {
            margin-right: 15px;
        }
    }
}

.incentive-status {
    position: absolute;
    right: 0px;
    top: 24px;
}

.overlay-tooltip {
.tooltip-inner{
    background: #fff;
      box-shadow: 0 2px 14px rgba(31,45,78,.14);
      border-radius: 6px;
      width: 300px!important;
      padding: 20px!important;
      font-weight: normal;
      white-space: normal;
      font-size: 17px;
      margin-right: 15px;
  }
}
.funnelreport-toolbar{
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 2px;
}