.fig-cap-outer-bg{
    width: 450px;
    background: $white;
    padding: 20px;
    display: block;
    margin: 0 auto;
    .fid-heading{
        font-size: 14px;
        font-weight: 500;
        color: $heading-color;
    }
    @media screen and (max-width: 767px) {
       
        width: 100%;

    }
    .dif-mmv{
        box-shadow: 0px 3px 8px 0px #0000000A;
        border: 1px solid #E2E2E2;
        border-radius: 10px;
        padding: 20px;
        margin: 20px 0;
        .fid-loan-id{
            font-size: 16px;
            font-weight: 500;
            color: $heading-color;
            span{
                font-size: 11px;
                font-weight: 400;
                display: block;
            }
        }
        .dealer-name-gcd-code{
            background: #F6F8FB;
            font-size: 12px;
            font-weight: 500;
            padding: 15px 10px;
            width: calc(100% + 40px);
            margin: 8px -20px;
            display: flex;
            align-items: center;
            color: $heading-color;
            span{
                width: calc(50% - 20px);
                padding: 0px 10px;
            }
        } 
        .fid-detail-list{
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                    width: 50%;
                    padding-bottom: 10px;
                    label{
                        font-size: 10px;
                        font-weight: 400;
                        display: block;
                    }
                    span{
                        font-size: 12px;
                        font-weight: 500;

                    }
                }
            }
        }  
    }
}
.geo-taging-pic-card{
    .accordion{
    box-shadow: 0px 2px 4px 0px #24272C0F;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 0px;
    display: inline-block;
    width: 100%;
   .accordion__heading {
        font-size: 14px;
        font-weight: 500;
        color: $heading-color;
        position: relative;
        border-bottom: 1px solid #E0E0E0;
        padding: 0px 20px ;
        .accordion__button {
            background: transparent;
            border: 0px;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: $heading-color;
             display: flex;
             align-items: center;
             justify-content: space-between;
             width: 100% !important;
             cursor: pointer;
             padding: 15px 0;

            &:after {
                content: "\e917";
                background-repeat: no-repeat;
                transition: transform .2s ease-in-out;
                font-family: dc;
                position: absolute;
                right: 20px;
                position: absolute;
                right: 20px;
                font-size: 25px;

            }
        }

        .accordion-button:not(.collapsed):after {
            content: "\e91a";
        }
    }
    .geo-taging-img-upload{
        display: flex;
        align-items: center;
        padding: 0px 10px 20px;
        flex-wrap: wrap;
        justify-content: center;

        li{
            width: calc(50% - 0px);
            padding: 10px;
            .fid-doc-head {
                font-size: 13px;
                font-weight: 500;
                color: $heading-color;
                padding-bottom: 8px;
                display: block;
            }
            .fid-image-container{
                background: #F4F7FA;
                border: 1px solid #D4E0EB;
                height: 84px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                position: relative;
                button{
                    border: 0px;
                    background: transparent;
                    .ic-add{
                        display: block;
                        text-align: center;
                        width: 100%;
                        margin: 0 auto;
                        font-size: 30px;
}
                    }
                    .fid-cross-icon{
                        background: #666666;
                        height: 16PX;
                        width: 16px;
                        border-radius: 16px;
                        position: absolute;
                        top: 0px;
                       
                        right: 0px;
                    }
                   
                }
            }
        }
    }
}

.btn-full-width{
    min-width: auto;
    width: 100%;
    margin-top: 20px;
    &.fid-submit {
        color: $white;
    }
}
.camerabtn{
    clear: both;
    margin-top: 20px !important;
    z-index: 99999999;
}
