.chat-app-outer{
  background: rgba($heading-color, 0.65);
  position: fixed;
  left: 0;
  right: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 9;
  .chatbox-main{
    background: #F2F5FC; 

    width: 504px;
    height: 100%;
    position: fixed;
    right: 0px;
    top: 0px;
    border-radius: 20px 0 0px 20px;
    &::after{
        content: '';
        background: url(../../images/chat_bg.svg);
        background-repeat: repeat;
        background-position: top left;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.2;
        top: 0px;
        left: 0px;
        z-index: -1;
    }
    .chat-header{
        background:$white;
        box-shadow: 0px 0.5px 9px 0px #9C9C9C33;
        padding: 20px 30px 20px 40px;
        border-radius: 20px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header-left{
        h3{
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            color: $heading-color;
            display: block;
        }
        span{
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            color: $heading-color;

        }
    }
        .header-right{
            display: flex;
            align-items: center;
            .resolved-tag{
                background: #17C34A;
                height: 24px;
                padding: 0px 20px;
                border-radius: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: $white;
            }
            i{
                font-size: 20px;
                margin-left: 20px;
                color: $heading-color;
                cursor: pointer;
            }
        }
    }
    .chat-history{
        padding: 20px;
        display: flex;
        height: calc(100% - 214px);
        overflow-y: auto;
        
        ul{
            .chat-left{
                max-width: 380px;
                padding-bottom: 8px;
                float: left;
                width: 100%;
                .chat-text{
                    box-shadow: 0px 2px 4px 0px #D6D6D680;
                    background: $white;
                    font-size: 13px;
                    font-weight: 400;
                    padding: 15px;
                    color: $heading-color;
                    border-radius: 20px 20px 20px 0;
                    float: left;
                    word-break: break-all;
                    overflow-wrap: break-word;
                }
                    .chat-time{
                        font-size: 10px;
                        font-weight: 400;
                        color: rgba($heading-color, 0.5);
                        float: left;
                        padding-top: 6px;
                        width: 100%;
                    }
                
            }
            .chat-right{
                max-width: 380px;
                padding-bottom: 8px;
                float: right;
                width: 100%;
                .chat-text{
                    box-shadow: 0px 2px 4px 0px #D6D6D680;
                    background: #007FFF;
                    font-size: 13px;
                    font-weight: 400;
                    padding: 15px;
                    color: $white;
                    border-radius: 20px 20px 0px 20px;
                    float: right;
                    position: relative;
                    word-break: break-all;
                    overflow-wrap: break-word;
                }
                    .chat-time{
                        font-size: 10px;
                        font-weight: 400;
                        color: rgba($heading-color, 0.5);
                        padding-top: 6px;
                        text-align: right;
                        float: right;
                        width: 100%;
                    }

                    .read-icn {
                        position: absolute;
                        right: 8px;
                        bottom: 8px;
                    }
                
            }

        }

    }
    .reopened-chat{
        float: left;
        width: 100%;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 25px;
        .reopened-txt{
        font-size: 11px;
        font-weight: 400;
        color: $heading-color;
        position: relative;
        &::after{
            content:'';
            background:rgba($heading-color, 0.20);
            position: absolute;
            right: -20px;
            width: calc(50% - 30px);
            top: 7px;
            height: 2px;
        }
        &::before{
            content:'';
            background:rgba($heading-color, 0.20);
            position: absolute;
            left: -20px;
            width: calc(50% - 30px);
            top: 7px;
            height: 2px;
        }
        }
    }
 
.defaut-msg{
display: flex;
align-items: center;
justify-content: center;
position: absolute;
bottom: 90px;
right: 20px;
    span{
    border: 1px dashed #B6C3D7;
    height: 36px;
    background: $white;
    padding: 0px 15px;
    font-size: 12px;
    font-weight: 400;
    color: $heading-color;
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    }
}
.chat-msg-box{
    background: $white;
    box-shadow: 0px 0.5px 9px 0px #9C9C9C33;
    border-radius: 0 0 0 20px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .form-input{
        border: 0px;
        height: 56px;
        width: calc(100% - 40px);
        font-size: 14px;
        font-weight: 400;
        color: rgba($heading-color, 0.5);
        padding: 10px;
    }
    .chat-send-btn{
        background: linear-gradient(126.52deg, #F640C3 -10.37%, #F66840 53.87%);
        height: 36px;
        width: 36px;
        border-radius: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i{
            font-size: 20px;
            color: $white;
        }
    }
}

  }
}